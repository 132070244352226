const pricelists = [
    { name: 'Default', scope: 'Company', products: '55', customers: 'All' },
    { name: 'Public Holidays', scope: 'Company', products: '55', customers: 'All' },
    { name: 'Happy Hour', scope: 'Company', products: '55', customers: 'All' },
    { name: 'Wholesale', scope: 'Company', products: '12', customers: '300' },
    { name: 'Wholesale Site', scope: 'Site', products: '10', customers: '86' },
    { name: 'Wholesale Customer', scope: 'Customer', products: '10', customers: '86' },
    { name: 'Discount Site', scope: 'Site', products: '55', customers: 'All' },
  ]
  
  export default function PriceListsTab() {
    return (
      <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-8">
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">

      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Price Lists</h1>
            <p className="mt-2 text-sm text-gray-700">
              [Search/filter price lists; filter by site, customer, status, etc]<br/>
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add Price List
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Scope
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Products
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Customers
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit or Clone</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {pricelists.map((pricelist) => (
                      <tr key={pricelist.name}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {pricelist.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{pricelist.scope}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{pricelist.products}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{pricelist.customers}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <a href="#" className="text-indigo-600 hover:text-indigo-900">
                            Edit<span className="sr-only">, {pricelist.name}</span>
                          </a> | <a href="#" className="text-indigo-600 hover:text-indigo-900">
                            Clone<span className="sr-only">, {pricelist.name}</span>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
      </main>
  )
  }
  