import { useState } from 'react'
import {
    CubeIcon,
  } from '@heroicons/react/24/outline'

const secondaryNavigation = [
    { name: 'Site 1', href: '#', icon: CubeIcon, current: true },
    { name: 'Site 2', href: '#', icon: CubeIcon, current: false },
    { name: 'Site 3', href: '#', icon: CubeIcon, current: false },
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  export default function Sites() {
    const [selectedPage, setSelectedPage] = useState(secondaryNavigation.find((page) => page.current).name)

    let pageContent = null
    switch (selectedPage) {
      case 'Site 1':
          pageContent = null//<Site />
          break;
      default:
          //pageContent = <Site />
    }
  
    return (
        <div className="mx-auto max-w-7xl pt-16 lg:flex lg:gap-x-16 lg:px-8">
        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-8">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    onClick={(event) => setSelectedPage(item.name)}
                    className={classNames(
                        item.name === selectedPage
                        ? 'bg-gray-50 text-indigo-600'
                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.name === selectedPage ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                        'h-6 w-6 shrink-0'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </aside>
        {pageContent}
      </div>
    )
  }
  