import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import { Bars3Icon } from '@heroicons/react/20/solid'
import {
  BellIcon,
  Cog8ToothIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline'

import Dashboard from '../backoffice/pages/Dashboard'
import Products from '../backoffice/pages/Products'
import Sites from '../backoffice/pages/Sites'
import Settings from '../backoffice/pages/SettingsTop'

const navigation = [
    { name: 'Dashboard', href: '#', current: true },
    { name: 'Products', href: '#', current: false },
    { name: 'Customers', href: '#', current: false },
    { name: 'Marketing', href: '#', current: false },
    { name: 'Reports', href: '#', current: false },
    //{ name: 'Manage Sites', href: '#', current: false },
    //{ name: 'Settings', href: '#', current: false },
  ]
  
const userNavigation = [
    { name: 'Dark Mode', href: '#' },
    { name: 'Profile', href: '#' },
    { name: 'Sign out', href: '#' },
]

  
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function BackOfficeLayout() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const [selectedPage, setSelectedPage] = useState(navigation.find((page) => page.current).name)

  let pageContent = null
  switch (selectedPage) {
    case 'Dashboard':
        pageContent = <Dashboard />
        break;
    case 'Sites':
        pageContent = <Sites />
        break;
    case 'Products':
        pageContent = <Products />
        break;
    case 'Customers':
        pageContent = null//<Customers />
        break;
    case 'Marketing':
        pageContent = null//<Marketing />
        break;
    case 'Reports':
        pageContent = null//<Reports />
        break;
    case 'Settings':
        pageContent = <Settings />
        break;
    default:
        pageContent = <Dashboard />
  }

  return (
    <>
      <header className="absolute inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10">
        <div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
          <div className="flex flex-1 items-center gap-x-6">
            <button type="button" className="-m-3 p-3 md:hidden" onClick={() => setMobileMenuOpen(true)}>
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-5 w-5 text-gray-900" aria-hidden="true" />
            </button>
            <img
              className="h-8 w-auto"
              src="https://merchant.demo.mypreorder.com.au/store-files/img/logo.png"
              alt="mypreorder"
            />
          </div>
          <nav className="hidden md:flex md:gap-x-4 md:text-sm md:font-semibold md:leading-6 md:text-gray-700">
            {navigation.map((item, itemIdx) => (
              <a key={itemIdx} 
                onClick={(event) => setSelectedPage(item.name)}
                href={item.href}
                className={classNames(
                    item.name === selectedPage ? 'bg-gray-900 text-white' : 'text-gray-600 hover:bg-gray-700 hover:text-white',
                    'rounded-md px-3 py-2 text-sm font-medium'
                  )}>
                {item.name}
              </a>
            ))}
          </nav>
          <div className="flex flex-1 items-center justify-end gap-x-8">
          <button type="button" className="-m-2.5 p-1.5 text-gray-400 hover:text-gray-500">
              <span className="sr-only">View notifications</span>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <button type="button" className="-m-2.5 p-1.5 text-gray-400 hover:text-gray-500">
              <span className="sr-only">Help</span>
              <QuestionMarkCircleIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <button type="button" className="-m-2.5 p-1.5 text-gray-400 hover:text-gray-500" onClick={(event) => setSelectedPage('Settings')}>
              <span className="sr-only">Company Settings</span>
              <Cog8ToothIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            {/* 
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your profile</span>
              <img
                className="h-8 w-8 rounded-full bg-gray-800"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
            </a>
            */}
            {/* Profile dropdown */}
            <Menu as="div" className="relative">
                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                <span className="sr-only">Open user menu</span>
                <img
                    className="h-8 w-8 rounded-full bg-gray-50"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                />
                <span className="hidden lg:flex lg:items-center">
                    <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                    Tom Cook
                    </span>
                    <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
                </Menu.Button>
                <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                >
                <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                        {({ active }) => (
                        <a
                            href={item.href}
                            className={classNames(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                            )}
                        >
                            {item.name}
                        </a>
                        )}
                    </Menu.Item>
                    ))}
                </Menu.Items>
                </Transition>
            </Menu>
          </div>
        </div>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-white px-4 pb-6 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10">
            <div className="-ml-0.5 flex h-16 items-center gap-x-6">
              <button type="button" className="-m-2.5 p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(false)}>
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="-ml-0.5">
                <a href="#" className="-m-1.5 block p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="https://merchant.demo.mypreorder.com.au/store-files/img/logo.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="mt-2 space-y-2">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  onClick={(event) => setSelectedPage(item.name)}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      {pageContent}

    </>
  )
}
