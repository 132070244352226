import { useState } from 'react'
import {
    BellIcon,
    CreditCardIcon,
    CubeIcon,
    FingerPrintIcon,
    UserCircleIcon,
    UsersIcon,
    XMarkIcon,
  } from '@heroicons/react/24/outline'

import Subscription from '../pages/Subscription'

const secondaryNavigation = [
    { name: 'Company', href: '#', icon: CubeIcon, current: true },
    { name: 'Sites', href: '#', icon: CubeIcon, current: false },
    { name: 'Branding', href: '#', icon: CubeIcon, current: false },
    { name: 'Subscription', href: '#', icon: CubeIcon, current: false },  // inc. invoices
    { name: 'Security', href: '#', icon: FingerPrintIcon, current: false }, // Back Office Users, Kiosk, POS logins, etc
    { name: 'Features', href: '#', icon: CubeIcon, current: false },
    { name: 'Printing', href: '#', icon: CubeIcon, current: false },
    { name: 'Integrations', href: '#', icon: CubeIcon, current: false },
    { name: 'Fulfilment Types', href: '#', icon: CubeIcon, current: false },
    { name: 'Services', sub: 'Fulfilment types & trading hours', href: '#', icon: CubeIcon, current: false }, // fulfilment types and trading hours
    { name: 'Product Flags', href: '#', icon: CubeIcon, current: false },
    { name: 'Report Groups', href: '#', icon: CubeIcon, current: false },
    { name: 'Suppliers', href: '#', icon: CubeIcon, current: false },
    { name: 'Taxes', href: '#', icon: CubeIcon, current: false },
    { name: 'Policies', href: '#', icon: CubeIcon, current: false },
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  export default function Settings() {
    const [selectedPage, setSelectedPage] = useState(secondaryNavigation.find((page) => page.current).name)

    let pageContent = null
    switch (selectedPage) {
      case 'Subscription':
          pageContent = <Subscription />
          break;
      case 'Security':
          pageContent = null//<Security />
          break;
      case 'Branding':
          pageContent = null//<Branding />
          break;
      case 'Integrations':
          pageContent = null//<Integrations />
          break;
      case 'Fulfilments':
          pageContent = null//<Fulfilments />
          break;
      case 'Payments':
          pageContent = null//<Payments />
          break;
      default:
          pageContent = null
    }
  
    return (
        <div className="mx-auto max-w-7xl pt-16 lg:flex lg:gap-x-16 lg:px-8">
        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-8">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    onClick={(event) => setSelectedPage(item.name)}
                    className={classNames(
                        item.name === selectedPage
                        ? 'bg-gray-50 text-indigo-600'
                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.name === selectedPage ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                        'h-6 w-6 shrink-0'
                      )}
                      aria-hidden="true"
                    />
                    <span>{item.name}</span>
                    {item.sub ? <span className="ml-auto text-xs text-gray-500">{item.sub}</span> : null}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </aside>
        {pageContent}
      </div>
    )
  }
  