
// status? delete?
const products = [
    { image: 'https://mypreorder.s3-ap-southeast-1.amazonaws.com/production/menu-images/343/mi_49029.png', name: 'BAGUETTE', price: '5.50', tax: 'GST', collections: 'BREAD', price_lists: 'Default', modifiers: '' },
    { image: 'https://mypreorder.s3-ap-southeast-1.amazonaws.com/production/menu-images/343/mi_49050.png', name: 'CROISSANT', price: '5.00', tax: 'GST', collections: 'PASTRY', price_lists: 'Default', modifiers: '' },
    { image: 'https://mypreorder.s3-ap-southeast-1.amazonaws.com/production/menu-images/343/mi_49059.png', name: 'CHOC CROISSANT', price: '5.5', tax: 'GST', collections: 'PASTRY', price_lists: 'Default', modifiers: '' },
    { image: 'https://mypreorder.s3-ap-southeast-1.amazonaws.com/production/menu-images/343/mi_49004.png', name: 'DANISH', price: '6.00', tax: 'GST', collections: 'PASTRY', price_lists: 'Default', modifiers: '' },
    { image: 'https://mypreorder.s3-ap-southeast-1.amazonaws.com/production/menu-images/343/mi_49068.png', name: 'WHITE SOURDOUGH', price: '7.20', tax: 'GST', collections: 'BREAD', price_lists: 'Default', modifiers: '' },
  ]
  
  export default function ProductsTab() {
    return (
      <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-8">
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">

      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Products</h1>
            <p className="mt-2 text-sm text-gray-700">
                [Search/filter products; filter by site, price list, category etc]<br/>
                [Products editable inline, on click/tap line for slideover panel?]<br/>
                [Arrangement of products, within their categories]<br/>
                [Drag and drop modifiers onto products?]<br/>
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Bulk Update
            </button>
            </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add Products
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Image</span>
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Price
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Tax
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Categories
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Modifiers
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Price Lists
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit or Clone</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {products.map((product) => (
                      <tr key={product.name}>
                        <td className="whitespace-nowrap px-2 py-3 text-sm text-gray-500">
                          <img src={product.image} style={{maxWidth: '50px'}} />
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {product.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.price}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.tax}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.collections}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.modifiers}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.price_lists}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <a href="#" className="text-indigo-600 hover:text-indigo-900">
                            Edit<span className="sr-only">, {product.name}</span>
                          </a> | <a href="#" className="text-indigo-600 hover:text-indigo-900">
                            Clone<span className="sr-only">, {product.name}</span>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </main>
    )
  }
  