const stats = [
    { name: 'Open Orders', value: '58', change: '', changeType: 'negative' },
    { name: 'Number of Sales', value: '3,202', change: '-1.39%', changeType: 'negative' },
    { name: 'Average Sale', value: '$17.20', change: '+4.02%', changeType: 'positive' },
    { name: 'Total Sales', value: '$55,091.00', change: '+4.75%', changeType: 'positive' },
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  export default function Dashboard() {
    return (
        <div className="mx-auto max-w-7xl pt-16 lg:flex lg:gap-x-16 lg:px-8">

        <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
            <div
                key={stat.name}
                className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8"
            >
                <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
                <dd
                className={classNames(
                    stat.changeType === 'negative' ? 'text-rose-600' : 'text-gray-700',
                    'text-xs font-medium'
                )}
                >
                {stat.change}
                </dd>
                <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                {stat.value}
                </dd>
            </div>
            ))}
        </dl>
        </div>
    )
  }
  